import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import './about.css'
import { StaticImage } from "gatsby-plugin-image"

const path = "../../../../static/team/"

const About = () => (
  <Container className="text_container">
    <p>
      Przychodnia Amicus pomaga Państwa podopiecznym już od kilkunastu lat. Jej założycielem jest lek. wet.
      Tadeusz Czekalski. Zaczynaliśmy w naszym gabinecie na ul. Maćka z Bogdańca i aby móc się dalej
      rozwijać i być bliżej Państwa została otwarta druga przychodnia na ul. Tatrzańskiej. Naszym głównym
      celem jest oferowanie usług weterynaryjnych na najwyższym poziomie. Kładziemy nacisk na
      profilaktykę i odpowiednią diagnostykę pacjentów. Nieustannie podnosimy jakość świadczonych przez
      nas usług weterynaryjnych i wzbogacamy bazę sprzętową o najnowocześniejsze na rynku urządzenia
      diagnostyczne i zabiegowe. Posiadamy sprzęt tj. USG, RTG bezpośrednie, aparat do mierzenia ciśnienia,
      własne laboratorium, blok operacyjny wyposażony w aparat do znieczulenia wziewnego, respirator, laser
      chirurgiczny oraz szpital, gdzie w ciszy i spokoju pod bacznym okiem naszego personelu zwierzęta
      dochodzą do siebie po zabiegach. Nasi lekarze i technicy cały czas biorą udział w szkoleniach,
      warsztatach, konferencjach i kursach, stale podnosimy kwalifikacje, by jak najlepiej opiekować się
      Waszymi pupilami.
    </p>
    <div className="divider"></div>

    <Row style={{ width: '100%' }}>
      <Col xs={12} md={6} lg={4}>
        <div className="avatar_wrapper">
          <div className="avatar_border"></div>
          <StaticImage className="avatar_round" src={path + "tadeusz.jpg"} alt="Nasz lekarz" height="300"/>
        </div>
        <h4>lek. wet. Tadeusz Czekalski</h4>
        <h5>Specjalista chirurgii</h5>
        <p>
          Wcześniej pracował w warszawskich klinikach całodobowych, gdzie pod okiem najlepszych specjalistów
          uczył się zawodu. Oprócz prowadzenia gabinetów „Amicus”, doktor Czekalski wciąż podnosi swoje
          kompetencje poprzez udział w licznych szkoleniach i sympozjach. Ostatnio poszerzył swoje kwalifikacje
          na Kursie Nefrologii Klinicznej Psów i Kotów. Jego główne zainteresowania zawodowe to chirurgia,
          onkologia oraz ortopedia. Współpracuje z organizacjami pro zwierzęcymi i karmicielami zwierząt.
          Prywatnie lubi brydża oraz turystykę górską.
        </p>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <div className="avatar_wrapper">
          <div className="avatar_border"></div>
          <StaticImage className="avatar_round" src={path + "kaja.jpg"} alt="Nasz lekarz" height="300"/>
        </div>
        <h4>lek. wet. Kaja Hawryńska</h4>
        <h5>Specjalista diagnostyki laboratoryjnej</h5>
        <p>
          Absolwentka Wydziału Medycyny Weterynaryjnej warszawskiej SGGW z 2008 roku, w 2013 roku
          uzyskała tytuł specjalisty w dziedzinie weterynaryjnej diagnostyki laboratoryjnej. Przez 10 lat
          praktykowała w lecznicy całodobowej. Zawodowo interesuje się leczeniem zwierząt egzotycznych,
          endokrynologia, onkologią i ultrasonografią. Ukończyła kurs ESAVS Exotic Pets - small mammals 2022.
          Jej hobby to turystyka górska, kynologia, snooker i tenis
          ziemny. Właścicielka psów rasy skye terrier, chomików roborowskiego, papug kozich, żółwia stepowego
          i kumaków dalekowschodnich.
        </p>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <div className="avatar_wrapper">
          <div className="avatar_border"></div>
          <StaticImage className="avatar_round" src={path + "miroslawa.jpg"} alt="Nasz lekarz" height="300"/>
        </div>
        <h4>lek. wet. Mirosława Ziemba</h4>
        <h5>Kardiolog</h5>
        <p>
          Ukończyła Wydział Medycyny Weterynaryjnej Szkoły Głównej Gospodarstwa Wiejskiego w
          Warszawie pod okiem najlepszych kardiologów weterynaryjnych - dr n. wet. Magdaleny Garncarz oraz
          dr. Rafała Niziołka w specjalistycznej lecznicy weterynaryjnej Vetcardia.
          Jest lekarzem weterynarii zajmującym się kardiologią i pulmonologią. Od kilku lat jest
          członkiem Europejskiego Stowarzyszenia Kardiologii Weterynaryjnej (European Society of Veterinary
          Cardiology - ESVC). Ukończyła cztery kursy kardiologiczne na Uniwersytecie w
          Luksemburgu organizowane przez ESAVS (European School for Advanced Veterinary
          Studies). Specjalizuje się w profilaktyce, diagnozowaniu oraz leczeniu chorób układu krążenia i układu
          oddechowego. www.kardiologzwierzat.pl
        </p>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <div className="avatar_wrapper">
          <div className="avatar_border"></div>
          <StaticImage className="avatar_round" src={path + "michal.jpg"} alt="Nasz lekarz" height="300"/>
        </div>
        <h4>lek. wet. Michał Sawicki</h4>
        <h5>Internista</h5>
        <p>
          Absolwent Wydziału Medycyny Weterynaryjnej w Lublinie, stwarza przyjemna wizyta dla Waszych
          podopiecznych w gabinecie, większość zwierząt czuje się przy nim zrelaksowana, jego zamiłowania to
          radiologia oraz dermatologią. Prywatnie interesuje się nowinkami technologicznymi
        </p>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <div className="avatar_wrapper">
          <div className="avatar_border"></div>
          <StaticImage className="avatar_round" src={path + "kasia.jpg"} alt="Nasz lekarz" height="300"/>
        </div>
        <h4>lek. wet. Katarzyna Serafin</h4>
        <h5>Internista</h5>
        <p>
          Ukończyła SGGW w Warszawie w 2018 roku. Zawodowo interesuje się okulistyką, diagnostyką
          obrazową i chorobami wewnętrznymi psów i kotów, ciągle się kształci w interesujących ją dziedzinach
          poprzez szkolenia i konferencje. Jest w trakcie specjalizacji z radiologii weterynaryjnej. Prywatnie
          miłośniczka biegania i czytania książek kryminalnych.
        </p>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <div className="avatar_wrapper">
          <div className="avatar_border"></div>
          <StaticImage className="avatar_round" src={path + "emilia.jpg"} alt="Nasz lekarz" height="300"/>
        </div>
        <h4>lek. wet. Emilia Cicherska</h4>
        <h5>Internista</h5>
        <p>
          Absolwentka wydziału Medycyny Weterynaryjnej Uniwersytetu Przyrodniczego we Wrocławiu.
          Zawodowo interesuje się anestezjologią, diagnostyką obrazową i dermatologią. Prywatnie jest
          właścicielką adoptowanej suczki - Gruszki. Kocha dobre jedzenie, kryminały i DIY.
        </p>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <div className="avatar_wrapper">
          <div className="avatar_border"></div>
          <StaticImage className="avatar_round" src={path + "aksel.jpg"} alt="Nasz lekarz" height="300"/>
        </div>
        <h4>Aksel Śliwiński</h4>
        <h5>Technik weterynarii</h5>
        <p>
          Na co dzień interesuję się behawiorystką zwierzęcą oraz groomerstwem. Jestem również miłośnikiem dużych psich ras.
          W wolnym czasie lubię spacerować ze swoim psim przyjacielem - Niedźwiedziem. Moje hobby to szycie oraz podróże.
        </p>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <div className="avatar_wrapper">
          <div className="avatar_border"></div>
          <StaticImage className="avatar_round" src={path + "linux.png"} alt="Nasz lekarz" height="300"/>
        </div>
        <h4>Monika</h4>
        <h5>Technik weterynarii</h5>
        <p>
          Ukończyłam technikum weterynaryjne w 2016 roku, mam 2 psy. W wolnym czasie czytam książki,
          podróżuje w różne ciekawe miejsca i fotografuje naturę
        </p>
      </Col>
    </Row>
  </Container>
);

export default About;
