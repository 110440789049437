import React from 'react'
import Layout from '../components/containers/layout'
import Seo from "../components/seo/seo"
import Navbar from '../components/nav/navbar'
import Footer from '../components/nav/footer'
import Hr from '../components/hr/hr.js'
import About from '../components/containers/about/about.js'
import { getSrc } from "gatsby-plugin-image"

const seoImage = getSrc("../../static/team/team.jpg")

const ONas = () => (
  <Layout>
    <Seo title="Zespół lekarzy weterynarii"
         description="W Amicus pracują wykwalifikowani pracownicy, którzy kochają zwierzęta"
         image={ seoImage }
    />
    <Navbar />
    <Hr nazwa={ "O nas" }/>
    <About />
    <Footer />
  </Layout>
)
export default ONas;
